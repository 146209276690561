<template>
<transition name="fade">
  <div v-if="!state.loading">
    <div class="d-flex flex-wrap flex-stack my-5">

        <h2 v-if="state.allN2F && state.allN2F.length > 0" class="fw-bold my-2 d-none d-md-block text-uppercase text-white custom-title">
          Vos notes de frais
        </h2>

        <div v-if="state.allN2F && state.allN2F.length > 0" class="d-flex align-items-center my-2 w-100 w-md-auto">

          <button class="btn btn-primary w-100 w-md-auto" title="ajouter" @click="openN2F()">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="media/icons/duotune/arrows/arr075.svg" />
              </span>
            Nouvelle note de frais
          </button>
        </div>
        <!--end::Controls-->
      </div>
      <el-empty v-if="state.allN2F.length == 0"  description="Aucune note de frais pour le moment...">
        <br><el-button type="primary" @click="openN2F()" >Créer votre première note de frais !</el-button>
      </el-empty>
      <div class="row g-6 g-xl-9" v-if="state.allN2F && state.allN2F.length > 0">
        <div class="col-sm-6 col-xl-4" v-for="n2f in state.allN2F" :key="n2f.not_seq">
          <div class="card h-100">
            <CardN2f
              card-classes="h-100 border-hover"
              :title="n2f.not_libelle"
              :content="`${n2f.montant_euro} €`"
              color="primary"
              :footer-data="n2f.not_status"
              footer-text=""
              arrow="Up-right"
              :indicator-value="`${n2f.tot_depense} dépense${n2f.tot_depense <= 1 ? '':'s'}`"
              :indicator-label="`Créee le ${toDate(n2f.note_date_creation)}`"
              @click="openN2F(n2f.not_seq)" 
              :all="true"
            ></CardN2f>
          </div>
        </div>

      </div>

      <el-drawer v-model="state.openCloseN2FAddEdit" :with-header="false" size="900px" modal-class="modal-transp" :lock-scroll="false" :show-close="false">
        <N2FAddEdit apiName="getN2F"  :idN2F="state.N2FAddEdit" :isOpen="state.openCloseN2FAddEdit" :key="state.openCloseN2FAddEdit" @refresh="refresh" @closeN2FAddEdit="state.openCloseN2FAddEdit = 0"/>
      </el-drawer>

    </div>
  </transition>
</template>
  
  <script lang="ts">
  import { defineComponent, ref, onMounted, reactive } from "vue";
  import mAxiosApi from "@/api";
  import * as moment from "moment";
  import CardN2f from "./CardN2f.vue";
  import N2FAddEdit from "./N2FAddEdit.vue";
  import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
   
  export default defineComponent({
    name: "General",
    components : {
        CardN2f,
        N2FAddEdit,
    },
    setup() {

    const dialogImageUrl = ref('')
    const dialogVisible = ref(false)
    const disabled = ref(false)

    const state = reactive({
        allN2F : {} as any,
        N2FAddEdit : 0,
        openCloseN2FAddEdit : 0,
        loading : true,
    });
    
    onMounted(async() => {
      setCurrentPageBreadcrumbs("Note de frais", ["Pages", "Profile"]);

      await refresh();

      state.loading = false;

    });
  

      const toDate = (d) => {
        return moment.default(d).format("DD.MM.YYYY");
      };


      const openN2F = (id = 0) =>{
        state.N2FAddEdit = id;
        state.openCloseN2FAddEdit = 1;
      }

      const refresh = async () => {
        const getN2F = await mAxiosApi.prototype.getAxios("/getN2F");
        state.allN2F = getN2F.results;
      };
     
      return {
          disabled,
          dialogImageUrl,
          dialogVisible,
          state,
          toDate,
          openN2F,
          refresh
      };
    },
  });
  </script>