
import { defineComponent } from "vue";

export default defineComponent({
  name: "card-n2f",
  props: {
    cardClasses: String,

    icon: String,

    title: String,

    content: String,

    color: String,

    arrow: String,

    footerData: String,

    footerText: String,

    indicatorValue: String,

    indicatorLabel: String,
  },
setup() {

  const commify =  (n) => {
    var parts = n.toString().split(".");
      const numberPart = parts[0];
      const decimalPart = parts[1];
      const thousands = /\B(?=(\d{3})+(?!\d))/g;
      return numberPart.replace(thousands, "'") + (decimalPart ? "." + decimalPart : "");

  };
 
  return {
    commify,
  };
},
});

