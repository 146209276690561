
  import { defineComponent, ref, onMounted, reactive } from "vue";
  import mAxiosApi from "@/api";
  import * as moment from "moment";
  import CardN2f from "./CardN2f.vue";
  import N2FAddEdit from "./N2FAddEdit.vue";
  import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
   
  export default defineComponent({
    name: "General",
    components : {
        CardN2f,
        N2FAddEdit,
    },
    setup() {

    const dialogImageUrl = ref('')
    const dialogVisible = ref(false)
    const disabled = ref(false)

    const state = reactive({
        allN2F : {} as any,
        N2FAddEdit : 0,
        openCloseN2FAddEdit : 0,
        loading : true,
    });
    
    onMounted(async() => {
      setCurrentPageBreadcrumbs("Note de frais", ["Pages", "Profile"]);

      await refresh();

      state.loading = false;

    });
  

      const toDate = (d) => {
        return moment.default(d).format("DD.MM.YYYY");
      };


      const openN2F = (id = 0) =>{
        state.N2FAddEdit = id;
        state.openCloseN2FAddEdit = 1;
      }

      const refresh = async () => {
        const getN2F = await mAxiosApi.prototype.getAxios("/getN2F");
        state.allN2F = getN2F.results;
      };
     
      return {
          disabled,
          dialogImageUrl,
          dialogVisible,
          state,
          toDate,
          openN2F,
          refresh
      };
    },
  });
  