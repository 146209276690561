<template>
  <a class="card border border-2 border-gray-300 border-hover cursor-pointer" :class="cardClasses">
    <div class="card-header flex-nowrap border-0 pt-9">
      <div class="card-title m-0">
        <div class="symbol symbol-45px w-25px me-5" v-if="footerData=='Brouillon'">
          <i class="fs-3 bi bi-file-earmark-post" style="color: #ffbc01"></i>
        </div>
        <div class="symbol symbol-45px w-25px me-5" v-if="footerData=='En cours de traitement'">
          <i class="fs-3 bi-hourglass-split text-primary" ></i>
        </div>
        <div class="symbol symbol-45px w-25px me-5" v-if="footerData=='Refusé'">
          <i class="fs-3 bi-exclamation-octagon-fill text-danger" ></i>
        </div>
        <div class="symbol symbol-45px w-25px me-5" v-if="footerData=='En attente de paiement'">
          <i class="fs-3 bi-check-square-fill text-success" ></i>
        </div>
        <div class="symbol symbol-45px w-25px me-5" v-if="footerData=='Payé'">
          <i class="fs-3 bi-cash text-success" ></i>
        </div>  

        <span class="fs-4 fw-bold text-hover-primary text-gray-600 m-0">
          {{ title }}
        </span>
      </div>

    </div>

    <div class="card-body d-flex flex-column px-9 pt-0 pb-8">
      <div class="fs-2tx fw-bolder mb-3 text-gray-700">
        {{ commify(content) }}
      </div>

      <div class="d-flex align-items-center flex-wrap mb-5 mt-auto fs-6">
        <span :class="`svg-icon-${color}`" class="svg-icon svg-icon-3 me-1">
          <inline-svg :src="`media/icons/duotune/Navigation/${arrow}.svg`" />
        </span>

        <div :class="`text-${color}`" class="fw-bolder me-2">
          {{ footerData }}
        </div>

        <div class="fw-bold text-gray-400">
          {{ footerText }}
        </div>
      </div>

      <div class="d-flex align-items-center fw-bold">
        <span class="badge bg-light text-gray-700 px-3 py-2 me-2">
          {{ indicatorValue }}
        </span>

        <span class="text-gray-400 fs-7">{{ indicatorLabel }}</span>
      </div>
    </div>
  </a>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "card-n2f",
  props: {
    cardClasses: String,

    icon: String,

    title: String,

    content: String,

    color: String,

    arrow: String,

    footerData: String,

    footerText: String,

    indicatorValue: String,

    indicatorLabel: String,
  },
setup() {

  const commify =  (n) => {
    var parts = n.toString().split(".");
      const numberPart = parts[0];
      const decimalPart = parts[1];
      const thousands = /\B(?=(\d{3})+(?!\d))/g;
      return numberPart.replace(thousands, "'") + (decimalPart ? "." + decimalPart : "");

  };
 
  return {
    commify,
  };
},
});

</script>
